import React from "react";
import {
  BusinessImpactOptions,
  BusinessImpactOptionsProps,
} from "@ecologi/react-components/src/BusinessImpactOptions";
import { useRouter } from "next/router";

type Props = {
  data: BusinessImpactOptionsProps;
};

export default function ImpactOptions({ data }: Props) {
  const router = useRouter();
  return <BusinessImpactOptions {...data} slug={router?.query?.slug} />;
}
